export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

const routes = [
  {
    name: 'usher-checkin',
    displayName: 'menu.usher-checkin',
    meta: {
      icon: 'login',
    },
  },
  // {
  //   name: 'usher-checkout',
  //   displayName: 'menu.usher-checkout',
  //   meta: {
  //     icon: 'logout',
  //   },
  // },
  {
    name: 'usher-guest',
    displayName: 'menu.guests',
    meta: {
      icon: 'group',
    },
  },
  {
    name: 'settings',
    displayName: 'menu.settings',
    meta: {
      icon: 'settings',
    },
  },
]

const filteredRoutes = routes.filter((route) => route.name !== 'settings')

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: filteredRoutes as INavigationRoute[],
}
