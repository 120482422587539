import { defineStore } from 'pinia'

export const useAlertStore = defineStore({
  id: 'alert',
  state: () => ({
    alert: null,
    isVisible: false,
  }),
  actions: {
    success(message) {
      this.isVisible = true
      this.alert = { message, type: 'success' }
    },
    error(message) {
      this.isVisible = true
      this.alert = { message, type: 'danger' }
    },
    warning(message) {
      this.isVisible = true
      this.alert = { message, type: 'warning' }
    },
    clear() {
      this.isVisible = false
      this.alert = null
    },
  },
})
