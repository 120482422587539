import { defineStore } from 'pinia'
import { apiClient } from '../helpers/apiClient'
import { routes, generateEndpoint } from '../config/guestbookAPIRoutes'
import router from '../router'
import { useAlertStore } from './alert.store'
import { useToast } from 'vuestic-ui'
import { useGuestGroupStore } from './guest-groups.store'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: JSON.parse(localStorage.getItem('user')),
    returnUrl: null,
  }),
  actions: {
    async login(email, password) {
      const { init } = useToast()
      const alertStore = useAlertStore()
      const guestGroup = useGuestGroupStore()

      alertStore.clear()
      guestGroup.resetGuestGroupState()

      try {
        const response = await apiClient.post(generateEndpoint(routes.auth.login), {
          email: email,
          password: password,
        })

        this.user = response.data
        localStorage.setItem('user', JSON.stringify(response.data))

        init({ message: "You've successfully logged in", color: 'success' })

        return router.push(this.returnUrl || '/dashboard')
      } catch (error) {
        return alertStore.error(error)
      }
    },
    async logout() {
      if (!this.user) {
        const alertStore = useAlertStore()
        return alertStore.warning('User already logged out')
      }

      await apiClient.get(generateEndpoint(routes.auth.logout))

      const guestGroup = useGuestGroupStore()
      guestGroup.resetGuestGroupState()

      return this.resetUserLoginState()
    },
    resetUserLoginState() {
      this.user = null
      localStorage.removeItem('user')

      return router.push('/auth/login')
    },
    async isLoggedIn() {
      if (this.user) {
        try {
          await apiClient.get(generateEndpoint(routes.auth.tokenValidation))
        } catch (error) {
          console.log(error)

          return this.resetUserLoginState()
        }

        if (router.currentRoute?.value) {
          return router.push(router.currentRoute.value)
        }

        return router.push('/dashboard')
      }

      return router.push('/auth/login')
    },
    async isLoggedInThenReturnBool() {
      if (this.user) {
        try {
          await apiClient.get(generateEndpoint(routes.auth.tokenValidation))
        } catch (error) {
          console.log(error)

          this.user = null
          localStorage.removeItem('user')
          return false
        }

        return true
      }

      return false
    },
  },
})
