import { defineStore } from 'pinia'
import { apiClient } from '../helpers/apiClient'
import { routes, generateEndpoint } from '../config/guestbookAPIRoutes'
import router from '../router'
import { useAlertStore } from './alert.store'
import { useToast } from 'vuestic-ui'

export const useAuthStore = defineStore({
  id: 'usher-auth',
  state: () => ({
    usher: JSON.parse(localStorage.getItem('usher')),
    returnUrl: null,
  }),
  actions: {
    async login(accessCode) {
      const { init } = useToast()
      const alertStore = useAlertStore()

      alertStore.clear()

      try {
        const response = await apiClient.post(generateEndpoint(routes.auth.usherValidate), {
          access_code: accessCode,
        })

        this.usher = response.data
        localStorage.setItem('usher', JSON.stringify(response.data))

        init({ message: "You've successfully logged in", color: 'success' })

        return router.push(this.returnUrl || '/usher/events/guest')
      } catch (error) {
        return alertStore.error(error)
      }
    },
    async logout() {
      if (!this.usher) {
        const alertStore = useAlertStore()
        return alertStore.warning('Usher already logged out')
      }

      return this.resetUsherLoginState()
    },
    resetUsherLoginState() {
      this.usher = null
      localStorage.removeItem('usher')

      return router.push('/auth/usher-login')
    },
    async isLoggedInThenReturnBool() {
      if (this.usher) {
        try {
          await apiClient.post(generateEndpoint(routes.auth.usherValidate), {
            access_code: this.usher.unique_code,
          })
        } catch (error) {
          console.log(error)

          this.usher = null
          localStorage.removeItem('usher')
          return false
        }

        return true
      }

      return false
    },
  },
})
