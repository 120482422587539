import { defineStore } from 'pinia'
import { apiClient } from '../helpers/apiClient'
import { routes, generateEndpoint } from '../config/guestbookAPIRoutes'

export const useGuestGroupStore = defineStore({
  id: 'guests-groups',
  state: () => ({
    guestsGroups: JSON.parse(localStorage.getItem('guestsGroups')),
  }),
  actions: {
    resetGuestGroupState() {
      this.guestsGroups = null
      localStorage.removeItem('guest_group')
    },
    async storeGuestGroup(eventId) {
      try {
        const response = await apiClient.get(generateEndpoint(routes.guestGroup.fetchByEventIdAsSimpleList, eventId))

        this.guestsGroups = { ...this.guestsGroups, [`event_${eventId}`]: response.data }

        const currentItem = localStorage.getItem('guest_group')
        const latestItem = currentItem
          ? JSON.stringify({ ...JSON.parse(currentItem), [`event_${eventId}`]: response.data })
          : JSON.stringify({ [`event_${eventId}`]: response.data })

        localStorage.setItem('guest_group', latestItem)
      } catch (error) {
        console.error(`Error storing guests groups: ${error}`)
      }
    },
    async fetchGuestGroup(eventId) {
      try {
        let currentItem = localStorage.getItem('guest_group')

        if (!currentItem) {
          await this.storeGuestGroup(eventId)
          currentItem = localStorage.getItem('guest_group')
        }

        return currentItem ? JSON.parse(currentItem)[`event_${eventId}`] ?? [] : []
      } catch (error) {
        console.error(`Error fetching guests groups: ${error}`)
        return []
      }
    },
  },
})
