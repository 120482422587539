import axios from 'axios'
import { useAuthStore } from '../stores/auth.store'
import { useAuthStore as usherUseAuthStore} from '../stores/usher-auth.store'
import authTypeConfig from '../config/authType'

export const apiClient = {
  get: request('get'),
  put: request('put'),
  post: request('post'),
  delete: request('delete'),
}

function request(method) {
  return (url, body = null, authTypeParam = null) => {
    const authType = authTypeParam ? authTypeParam : authTypeConfig.bearerToken

    const defaultHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
    const authHeaderValue = authHeader(authType)
    const headers = { ...defaultHeaders, ...authHeaderValue }

    const params = { method: method, url: url, headers: headers }

    if (body) {
      params['data'] = body
    }

    return axios(params).then(responseHandler).catch(errorHandler)
  }
}

function authHeader(authTypeParam) {
  const { user } = useAuthStore()
  const isLoggedIn = !!user?.access_token

  if (isLoggedIn && authTypeParam === authTypeConfig.bearerToken) {
    return { Authorization: `Bearer ${user.access_token}` }
  }

  const { usher } = usherUseAuthStore()
  const isUsherLoggedIn = !!usher?.unique_code

  if (isUsherLoggedIn && authTypeParam === authTypeConfig.usherAccess) {
    return { 'X-USHER-ACCESS-CODE': `${usher.unique_code}` }
  }

  return {}
}

function responseHandler(response) {
  const isJson = response.headers?.get('content-type')?.includes('application/json')
  return isJson ? response.data : null
}

function errorHandler(error) {
  const { user, logout } = useAuthStore()
  const isJson = error.response.headers?.get('content-type')?.includes('application/json')
  const data = isJson ? error.response.data : null

  if ([401, 403].includes(error.response.status) && user) {
    logout()
  }

  let errorMessage = error.response.message

  if (data?.errors) {
    let errorArr = []

    for (let [key, value] of Object.entries(data.errors)) {
      errorArr = errorArr.concat(value)
    }

    errorMessage = errorArr.join('<br>')
  } else if (data?.message) {
    errorMessage = data?.message
  } else if (data?.data) {
    errorMessage = 'Failed to do http request'
  }

  return Promise.reject(errorMessage)
}
