import globalConfig from './globalConfig'

export const routes = {
  auth: {
    login: '/auth/login',
    usherValidate: '/usher-auth/validate',
    register: '/auth/register',
    tokenValidation: '/auth/token/validate',
    refreshToken: '/auth/token/refresh',
    logout: '/auth/logout',
  },
  event: {
    create: '/events',
    update: '/events/$1',
    fetchByUserId: '/events?per_page=$1&page=$2',
    fetchById: '/events/$1',
  },
  guest: {
    create: '/guest',
    update: '/guest/$1',
    fetchByUserId: '/guest?per_page=$1&page=$2',
    fetchByEventId: '/events/$1/guest?per_page=$2&page=$3',
    delete: '/guest/$1',
  },
  guestGroup: {
    create: '/guest/groups',
    update: '/guest/groups/$1',
    fetchByUserId: '/guest/groups?per_page=$2&page=$3',
    fetchByEventId: '/events/$1/guest/groups?per_page=$2&page=$3',
    fetchByEventIdAsSimpleList: '/events/$1/guest/groups/simple',
    delete: '/guest/groups/$1',
  },
  config: {
    fetchByKey: '/config/key/$1',
    upsertBasedOnEvent: '/config/event',
  },
  broadcast: {
    invitation: '/events/$1/broadcast/invitation',
  },
  usher: {
    create: '/usher',
    update: '/usher/$1',
    delete: '/usher/$1',
    fetchByEventId: '/events/$1/usher?per_page=$2&page=$3',
  },
  attendance: {
    checkIn: '/attendance/check-in',
    guest: '/attendance/guests?per_page=$1&page=$2',
  },
}

export const generateParam = (endpoint, params) => {
  const endpointParam = endpoint.match(/\$[0-9]+/g)

  if (endpointParam && endpointParam.length > 0) {
    endpointParam.forEach((element, index) => {
      endpoint = endpoint.replace(element, params[index])
    })
  }

  return endpoint
}

export const generateRoute = (key, ...params) => {
  const arrKey = key.split('.')
  let endpoint = arrKey.length > 1 ? routes[arrKey[0]][arrKey[1]] : routes[arrKey[0]]
  endpoint = generateParam(endpoint, params)

  return globalConfig.api + endpoint
}

export const generateEndpoint = (endpoint, ...params) => {
  endpoint = generateParam(endpoint, params)
  return globalConfig.api + endpoint
}
